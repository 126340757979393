<template>
    <perfect-scrollbar
        tag="div"
        class="companies__list"
    >
        <company
            v-for="domain in otherDomains"
            :key="domain.id"
            :domain="domain"
        />
    </perfect-scrollbar>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "List",
    components: {
        Company : () => import('./Company')
    },
    computed: {
        ...mapGetters('sidebar', [
            'otherDomains'
        ]),
    }
}
</script>

<style lang="scss">
@import "#sass/v-style";
.companies__list {
    max-height: 200px;
    overflow-y: scroll;
    .menu-dropdown {
        &__company {
            opacity: 0.5;
            transition: opacity $ease;
            &:hover {
                opacity: 1;
            }
        }
    }
}
</style>
